import { QT } from "../components";
import * as React from "react"


const QuickTask = () => {
    return(
        <QT />
    )
}

export default QuickTask;